
table {
  width: 100%;
}

.nom {
    padding: 10px;
    width: calc(100% - 50px);
  }

  .score {
    width: 50px;
    padding: 10px;
  }


.footer-container {
    background-color: #001e50;
    margin-top: 20px !important;
    padding-top: 20px;
    width: calc(100vw - 20px);
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {

    .footer-container {
        width: calc(100vw - 10px);
    }

}

.footer-container ul {
    list-style-type: none;
    margin-bottom: 10px;
}

.footer-container a, .footer-container a:hover, .footer-container span {
    display: inline-block;
    text-decoration: none;
    color: #d2d2d3;
    font-size: 14px;
    letter-spacing: .75px;
    line-height: 0.3;
    margin-bottom: 7px;
}

.footer-container .has-children>ul:before {
    background-color: #ef3346;
    content: "";
    display: block;
    height: 2px;
    margin: 8px 0 12px;
    width: 32px;
    margin-top: 2px;
}

.footer-container .has-children > a {
    margin-top: 20px;
    font-weight: bold;
}

.footer-container ul {
    padding-left: 0;
}

.footer-container .column3-footer {
    margin-top: 0;
    font-weight: bold;
    line-height: 3;
}

.footer-end {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-bottom: 10px;
}

/* FooterMecalliance */

.footer-banner {
    margin-left: -15px;
    width: calc(100% + 20px) !important;
    max-width: calc(100% + 20px) !important;
    background-color: #01112c;
}

@media screen and (max-width: 767px) {

    .footer-banner .mecallians-banner {
        padding: 10px;
    }

}

.footer-banner .position {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 139px;
}

.footer-banner .mecallians-banner {
    color: #d2d2d3;
    display: flex;
    font-size: 14px;
    gap: 42px;
    justify-content: center;
    letter-spacing: .75px;
}

.footer-banner .mecallians-banner .moto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 468px;
}
.navbar-container.largeur-100 {
    width: calc(100vw - 18px);
    margin-left: -12px;
}

@media screen and (max-width: 767px) {

    .navbar-container.largeur-100 {
        width: calc(100vw + 20px);
        margin-left: -2px;
    }

}

.navbar-container {
    font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.niveau-barre {
    display: flex;
    height: 10px;
    width: 100%;
    border: 1px solid silver;
  }
  
  .niveau-barre-remplie {
    background-color: rgb(79, 127, 199);
    height: 100%;
  }


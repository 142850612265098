.btn-primary {
    color: white !important;
    background: black !important;
    border: black !important;
}

.customLink:hover {
    text-decoration: underline !important;
}

.facettes {
    margin-top: 10px;

}

h5 p {
    font-size: 100% !important;
}

@media screen and (min-width: 767px) {
/* gestion avec ascenceur incompatible avec footer
    .facettes {
        overflow-y: auto;
        max-height: calc(100vh - 450px);
    }

    .resultats {
        overflow-y: auto;
        max-height: calc(100vh - 190px);
    }
*/
    .resultats {

    }

}

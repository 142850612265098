
@font-face {
  font-family: "Source Code Pro";
     src : url('assets/fonts/Otf/Codec-Pro-Regular.otf');
     src : url('assets/fonts/Woff/Codec-Pro-Regular.woff') format('woff'),
           url('assets/fonts/Ttf/Codec-Pro-Regular.ttf') format('truetype');		  
 }

body {
  margin: 0;
  font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin-left: 0;
  margin-right: 0;
  min-width: 100%;
}

.row.container {
  min-width: 100%;
}
.icon {
    font-size: 32px;
    color: #CA0538;
    /*opacity: 0.8;*/
}

.number {
    font-family: "open-sans-bold", sans-serif;
    font-size: 40px;
    font-weight: 800;
}

.text {
    font-family: "open-sans-regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.app {
  padding: 10px;
}

.app .jumbotron {
  text-align: center;
}

.app .card {
  margin-bottom: 10px;
}

.app .card-header {
  color: white;
  font-weight: bold;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/*
footer.bg-dark {
  background-color: #CA0538 !important
}

footer {
  color: #AAAAAA;
}
*/

footer {
  margin: 20px;
  padding: 10px;
}

.bouton-recherche {
  margin: 2px;
  width: 57px;
  height: 57px;
  background-color: #001e50 !important;
}

@media screen and (max-width: 767px) {

  .container {
    margin: 0 !important;
    padding: 2px !important;
  }

  .App {
    padding: 0 !important;
  }

}
